import React, { useState, useContext } from 'react';
import { UserContext } from '../components/context/UserContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importamos íconos de react-icons

const LoginForm = ({ setShowLogin, companySlug }) => {
  const { setUser, login } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await fetch('https://admin.kuvaway.com/api/customer-login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email.trim(), password: password.trim() }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Invalid credentials');
      }
      if (login) {
        login(data.user, data.token);
      } else {
        setUser(data.user);
      }
      if (setShowLogin) {
        setShowLogin(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://admin.kuvaway.com/api/request-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: resetEmail }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Error requesting password reset');
      }
      setResetMessage('A reset link has been sent to your email.');
    } catch (err) {
      setResetMessage(err.message);
    }
  };

  return (
    <div className="space-y-6">
      {showResetPassword ? (
        <form onSubmit={handleResetPassword} className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-800">Reset Password</h3>
          <input
            type="email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg"
            required
          />
          {resetMessage && <p className="text-sm text-gray-600">{resetMessage}</p>}
          <button
            type="submit"
            className="w-full px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
          >
            Send Reset Link
          </button>
          <button
            type="button"
            onClick={() => setShowResetPassword(false)}
            className="w-full px-6 py-3 text-blue-600 font-semibold"
          >
            Back to Login
          </button>
        </form>
      ) : (
        <form onSubmit={handleLogin} className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-800">Login</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg"
            required
          />
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'} // Cambia entre text y password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600 hover:text-gray-800"
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </button>
          </div>
          {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
          <button
            type="submit"
            className="w-full px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
          >
            Login
          </button>
          <button
            type="button"
            onClick={() => setShowResetPassword(true)}
            className="w-full px-6 py-3 text-blue-600 font-semibold"
          >
            Forgot your password?
          </button>
        </form>
      )}
    </div>
  );
};

export default LoginForm;