import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
  Link
} from 'react-router-dom';

import CompanyLayout from './global-layout/CompanyLayout';
import LandingPage from './components/LandingPage';
import RoutesPage from './components/AvailableRoutes';
import ArchiveRoutes from './components/ArchiveRoutes';
import SearchResults from './components/SearchResults';
import Checkout from './components/Checkout';
import ThankYou from './components/ThankYou';
import MyAccount from './components/MyAccount';
import Support from './components/Support';
import SignIn from './components/SignIn';
import LogIn from './components/LogIn';
import Schedules from './components/Schedules';
import ContactUs from './components/ContactUs';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import HelpCenter from './components/HelpCenter';
import ForgotPassword from './components/shared/ForgotPassword';
import ResetPassword from './components/shared/ResetPassword';
import Security from './components/Security';
import { UserContext, UserProvider } from './components/context/UserContext';
import { Navigate } from 'react-router-dom'; // asegúrate de que esté importado


// Componente de ruta protegida
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);
  const { companySlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      navigate(`/${companySlug}/log-in`, { state: { from: location } });
    }
  }, [user, loading, companySlug, navigate, location]);

  if (loading) return <div className="text-center py-12">Cargando...</div>;

  return user ? children : null;
};

// Wrapper del Checkout para validar los datos
const CheckoutWrapper = () => {
  const { state } = useLocation();
  const { purchaseData, companySlug } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!purchaseData || !companySlug) {
      navigate(`/${companySlug || 'transporzuma'}/search-results`);
    }
  }, [purchaseData, companySlug, navigate]);

  if (!purchaseData || !companySlug) {
    return <div className="text-center py-12">Redirigiendo...</div>;
  }

  return <Checkout purchaseData={purchaseData} />;
};

// Página de error 404
const NotFound = () => {
  const location = useLocation();
  const { companySlug } = useParams();

  return (
    <div className="text-center py-12">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">404 - Página no encontrada</h1>
      <p>Ruta no encontrada: {location.pathname}</p>
      <p className="mt-4">
        <Link to={`/${companySlug || 'transporzuma'}`} className="text-blue-600 hover:underline">
          Volver a la página principal
        </Link>
      </p>
    </div>
  );
};

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          {/* Página principal */}
          <Route path="/" element={<Navigate to="/transporzuma" replace />} />

          {/* Rutas para cada compañía */}
          <Route path="/:companySlug" element={<CompanyLayout />}>
            <Route index element={<LandingPage />} />
            <Route path="routes" element={<RoutesPage />} />
            <Route path="archive-routes" element={<ArchiveRoutes />} />
            <Route path="search-results" element={<SearchResults />} />
            <Route path="checkout" element={<CheckoutWrapper />} />
            <Route path="thank-you" element={<ThankYou />} />
            <Route
              path="my-account"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="support"
              element={
                <ProtectedRoute>
                  <Support/>
                </ProtectedRoute>
              }
            />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="log-in" element={<LogIn />} />
            <Route path="schedules" element={<Schedules />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="help-center" element={<HelpCenter />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="security" element={<Security/>} />
          </Route>

          {/* Ruta por defecto (404) */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
