import React, { createContext, useState, useEffect } from 'react';

// Crear el contexto
export const UserContext = createContext();

// Proveedor del contexto
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Estado del usuario
  const [token, setToken] = useState(localStorage.getItem('token') || null); // Estado del token
  const [loading, setLoading] = useState(true); // Estado de carga para la verificación inicial
  const [error, setError] = useState(null); // Estado para manejar errores

  // Verificar el token al cargar la aplicación
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    console.log('UserContext - Initial token check:', { storedToken, user, loading });
    if (storedToken && !user) {
      // Solo fetch user data if there's a token and no user data (to avoid fetching after login)
      fetchUserData(storedToken);
    } else {
      console.log('UserContext - No token or user already set, setting loading to false');
      setLoading(false);
    }
  }, []);

  // Función para obtener los datos del usuario desde el backend
  const fetchUserData = async (token) => {
    try {
      setLoading(true);
      setError(null);
      console.log('UserContext - Fetching user data with token:', token);

      const response = await fetch('https://admin.kuvaway.com/api/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('UserContext - Fetch response status:', response.status);
      if (response.ok) {
        const data = await response.json();
        console.log('UserContext - User data fetched successfully:', data);
        setUser(data);
        setToken(token);
      } else {
        const errorData = await response.json();
        console.log('UserContext - Invalid token or response error:', errorData);
        setError(errorData.message || 'Invalid token');
        logout();
      }
    } catch (error) {
      console.error('UserContext - Error fetching user data:', error);
      setError('Failed to fetch user data');
      logout();
    } finally {
      setLoading(false);
      console.log('UserContext - Fetch completed, current state:', { user, token, loading, error });
    }
  };

  // Función para iniciar sesión
  const login = (userData, token) => {
    console.log('UserContext - Logging in with userData:', userData, 'and token:', token);
    setUser(userData);
    setToken(token);
    localStorage.setItem('token', token);
    setLoading(false);
    setError(null);
    console.log('UserContext - Login successful, current state:', { user: userData, token, loading });
  };

  // Función para cerrar sesión
  const logout = () => {
    console.log('UserContext - Logging out');
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
    setLoading(false);
    setError(null);
    console.log('UserContext - Logged out, current state:', { user, token, loading });
  };

  return (
    <UserContext.Provider value={{ user, setUser, token, login, logout, loading, error, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};