import React from 'react';

const Hero = ({ slides = [] }) => {
  // Si no hay slides, mostrar un hero por defecto
  if (slides.length === 0) {
    return (
      <section className="relative w-full min-h-[30vh] bg-gray-800 flex items-center justify-center">
        <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
          <div className="text-center text-white">
            <h1 className="text-4xl font-bold mb-4">Transporzuma!</h1>
            <p className="text-lg">San José | Montezuma | Santa Teresa | Guanacaste</p>
          </div>
        </div>
      </section>
    );
  }

  // Tomamos la primera imagen del array slides
  const slide = slides[0];

  return (
    <section className="relative w-full min-h-[30vh] overflow-hidden">
      {/* Imagen estática */}
      <div
        className="w-full min-h-[50vh] bg-cover bg-center relative"
        style={{
          backgroundImage: `url(${slide.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* Overlay con el mismo color que el Navbar */}
        <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
          <div className="text-center text-white px-4">
            {/* Título */}
            {slide.title && (
              <h1 className="text-4xl md:text-5xl font-bold mb-4">
                {slide.title}
              </h1>
            )}
            {/* Párrafo */}
            {slide.paragraph && (
              <p className="text-lg md:text-xl mb-6">
                {slide.paragraph}
              </p>
            )}
            {/* No mostramos el botón */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;