import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './context/UserContext';
import Drawer from './Drawer';

const MyAccount = () => {
  const { user, token, loading } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.customer_id) return;

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      try {
        const [ordersRes, ticketsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/customer-orders/${user.customer_id}`, { headers }),
          fetch(`${process.env.REACT_APP_API_URL}/api/customer-tickets/${user.customer_id}`, { headers }),
        ]);

        const ordersData = await ordersRes.json();
        const ticketsData = await ticketsRes.json();

        if (ordersRes.ok) {
          const grouped = {};
          for (const order of ordersData) {
            const id = order.order_id;
            if (!grouped[id]) {
              grouped[id] = {
                order_db_id: order.order_db_id,
                order_id: order.order_id,
                company_id: order.company_id,
                customer_id: order.customer_id,
                travel_date: order.travel_date,
                return_date: order.return_date,
                created_date: order.created_date,
                updated_date: order.updated_date,
                status: order.status,
                billing_first_name: order.billing_first_name,
                billing_last_name: order.billing_last_name,
                billing_email: order.billing_email,
                billing_phone: order.billing_phone,
                billing_country: order.billing_country,
                passengers: order.passengers,
                public_notes: order.public_notes,
                private_notes: order.private_notes,
                subtotal: order.order_subtotal,
                services_tax: order.order_tax_amount, // Aseguramos que services_tax se asigne correctamente
                total_amount: order.order_total_amount,
                pickup_location: order.pickup_location,
                dropoff_location: order.dropoff_location,
                payment_method: order.payment_method,
                payment_status: order.payment_status,
                items: [],
              };
            }
            grouped[id].items.push({
              item_id: order.order_item_id,
              details_route: order.details_route,
              quantity: order.quantity,
              trip_type: order.trip_type,
              unit_price: order.unit_price,
              subtotal: order.subtotal,
              tax_amount: order.tax_amount,
              schedule_time: order.departure_time,
              link_name: order.link_name,
            });
          }
          setOrders(Object.values(grouped));
        }

        if (ticketsRes.ok) {
          setTickets(ticketsData);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    if (!loading) fetchData();
  }, [user, token, loading]);

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setDrawerOpen(true);
  };

  const formatDate = (date) => new Date(date).toLocaleDateString();

  if (loading) return <p className="text-gray-500">Loading your data...</p>;
  if (!user) return <p className="text-red-500">Please log in to view your account.</p>;

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">My Account</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Your Orders</h2>
        <p className="text-gray-600 mb-4">
          Below is a list of all your past and current orders. Check the details of each trip, including purchase date, trip type, status, and total amount.
        </p>

        {orders.length === 0 ? (
          <p className="text-gray-500">You have no orders.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full border divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">ID Order</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Purchase Date</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Trip Type</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Total Amount</th>
                  <th className="px-4 py-2"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {orders.map((order) => (
                  <tr key={order.order_id}>
                    <td className="px-4 py-2 text-sm text-gray-900 font-medium">{order.order_id}</td>
                    <td className="px-4 py-2 text-sm text-gray-700">{formatDate(order.created_date)}</td>
                    <td className="px-4 py-2 text-sm">
                      <span
                        className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${
                          order.items?.[0]?.trip_type === 'round_trip'
                            ? 'bg-blue-100 text-blue-700'
                            : 'bg-indigo-100 text-indigo-700'
                        }`}
                      >
                        {order.items?.[0]?.trip_type === 'round_trip' ? 'Round Trip' : 'One Way'}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm">
                      <span
                        className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${
                          order.status === 'completed'
                            ? 'bg-green-100 text-green-700'
                            : order.status === 'pending'
                            ? 'bg-yellow-100 text-yellow-700'
                            : order.status === 'cancelled' || order.status === 'refunded'
                            ? 'bg-red-100 text-red-700'
                            : 'bg-gray-200 text-gray-700'
                        }`}
                      >
                        {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">${Number(order.total_amount || 0).toFixed(2)}</td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => handleViewDetails(order)}
                        className="text-blue-600 hover:text-blue-800 font-semibold text-sm"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>

      {selectedOrder && (
        <Drawer
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          order={selectedOrder}
          tickets={tickets.filter((t) => t.order_id === selectedOrder.order_db_id)}
        />
      )}
    </div>
  );
};

export default MyAccount;