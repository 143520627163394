import React from 'react';
import { useOutletContext } from 'react-router-dom';

const ContactUs = () => {
  const { companySlug } = useOutletContext();

  return (
    <div className="max-w-4xl mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h1>
      <p className="text-gray-600 mb-6">This is a placeholder for the Contact Us page.</p>
      <p><strong>Company Slug:</strong> {companySlug}</p>
      {/* Add your contact form or content here */}
    </div>
  );
};

export default ContactUs;