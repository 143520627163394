import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { UserIcon, ArrowRightIcon, CheckCircleIcon, XMarkIcon, CalendarIcon, ClockIcon, UsersIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import BuyTickets from './BuyTickets';
import Hero from './Hero';

const SearchResults = () => {
  const { state } = useLocation();
  const { routes, links, companySlug, landingPage } = useOutletContext();
  const navigate = useNavigate();

  const [schedulesByLink, setSchedulesByLink] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [selectedRoute, setSelectedRoute] = useState(state?.selectedRoute || '');
  const [selectedLink, setSelectedLink] = useState('');
  const [travelDate, setTravelDate] = useState(state?.travelDate || '');
  const [returnDate, setReturnDate] = useState(state?.returnDate || '');
  const [passengers, setPassengers] = useState(state?.passengers || 1);
  const [from, setFrom] = useState(state?.from || '');
  const [to, setTo] = useState(state?.to || '');
  const [routeLinks, setRouteLinks] = useState(state?.links || []);

  const hasFetchedSchedules = useRef(false);
  const schedulesSectionRef = useRef(null);

  const heroSlides = [];
  if (landingPage && Object.keys(landingPage).length > 0) {
    for (let i = 1; i <= 3; i++) {
      const imageUrl = landingPage[`hero_image_${i}_url`];
      if (imageUrl) {
        heroSlides.push({
          url: imageUrl,
          title: landingPage[`hero_image_${i}_title`],
          paragraph: landingPage[`hero_image_${i}_paragraph`],
          buttonText: landingPage[`hero_image_${i}_button_text`],
          buttonUrl: landingPage[`hero_image_${i}_button_url`],
        });
      }
    }
  }

  const availableLinks = routeLinks?.filter((link) => link.link_name === to) || [];

  useEffect(() => {
    const fetchSchedules = async () => {
      if (hasFetchedSchedules.current) return;

      hasFetchedSchedules.current = true;

      const schedulesData = { ...schedulesByLink };

      for (const link of availableLinks) {
        if (schedulesData[link.link_id]) continue;

        try {
          const response = await fetch(`https://admin.kuvaway.com/api/schedules/${link.link_id}`);
          if (!response.ok) {
            console.error(`Error al obtener horarios para link_id ${link.link_id}: ${response.status}`);
            schedulesData[link.link_id] = [];
            continue;
          }
          const schedules = await response.json();
          schedulesData[link.link_id] = schedules;
        } catch (error) {
          console.error(`Error al obtener horarios para link_id ${link.link_id}:`, error);
          schedulesData[link.link_id] = [];
        }
      }

      setSchedulesByLink(schedulesData);
    };

    if (availableLinks.length > 0) {
      fetchSchedules();
    }
  }, [availableLinks, schedulesByLink]);

  const schedules = availableLinks.flatMap((link) => {
    const linkSchedules = schedulesByLink[link.link_id] || [];
    const times = linkSchedules.length > 0
      ? linkSchedules.map((schedule) => schedule.schedule_time)
      : ['N/A'];
    return times.map((time) => ({
      link,
      departureTime: time,
    }));
  });

  const formatTime = (time) => {
    if (!time || time === 'N/A') return 'N/A';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  const handleSelectSchedule = (link, departureTime) => {
    setSelectedSchedule({ link, departureTime });
  };

  const handleCloseDrawer = () => {
    setSelectedSchedule(null);
  };

  const handlePayOrder = () => {
    if (!selectedSchedule) return;
  
    const { link, departureTime } = selectedSchedule;
    // Buscar el schedule_id correspondiente al departureTime
    const linkSchedules = schedulesByLink[link.link_id] || [];
    const selectedScheduleData = linkSchedules.find(
      (schedule) => schedule.schedule_time === departureTime
    );
    const scheduleId = selectedScheduleData?.id || null;

    const purchaseData = {
      routeId: selectedRoute,
      routeName: selectedRouteName,
      toName: link.link_name,
      departureTime: departureTime || 'N/A',
      price: link.price || 0,
      travelDate,
      returnDate,
      passengers,
      linkId: link.link_id, // Añadir linkId
      scheduleId: scheduleId, // Añadir scheduleId
    };
  
    console.log('Navigating to checkout with purchaseData:', purchaseData);
    navigate(`/${companySlug}/checkout`, {
      state: { purchaseData, companySlug },
    });
  };

  const handleRouteChange = (e) => {
    const routeId = e.target.value;
    setSelectedRoute(routeId);
    setSelectedLink('');
    setSchedulesByLink({});
    hasFetchedSchedules.current = false;

    if (routeId && links[routeId]) {
      setRouteLinks(links[routeId]);
      const selectedRouteData = routes.find(
        (route) => String(route.route_id) === String(routeId)
      );
      setFrom(selectedRouteData?.route_name || '');
    }
  };

  const handleSearch = (newSearchData) => {
    const { from, to, travelDate, returnDate, passengers, links: newRouteLinks, selectedRoute } = newSearchData;

    setFrom(from);
    setTo(to);
    setTravelDate(travelDate);
    setReturnDate(returnDate || '');
    setPassengers(passengers);
    setRouteLinks(newRouteLinks);
    setSelectedRoute(selectedRoute);
    setSelectedLink('');
    setSchedulesByLink({});
    setSelectedSchedule(null);
    hasFetchedSchedules.current = false;

    if (schedulesSectionRef.current) {
      schedulesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const selectedRouteName = routes?.find((route) => route.route_id === selectedRoute)?.route_name || 'N/A';

  const pricePerPerson = selectedSchedule?.link.price || 0;
  const tripMultiplier = returnDate ? 2 : 1;
  const tripCost = pricePerPerson * passengers * tripMultiplier;
  const servicesTax = 4;
  const subtotal = tripCost + servicesTax;

  if (!from || !to || !travelDate || !routeLinks || !selectedRoute) {
    return (
      <div className="flex flex-col min-h-screen">   
        <div className="relative">
          <Hero slides={heroSlides} />
          <div id="buy-tickets" className="relative -mt-12 z-10">
            <BuyTickets
              routes={routes}
              links={links}
              onPurchase={() => {}}
              onRouteChange={handleRouteChange}
              selectedRoute={selectedRoute}
              selectedLink={selectedLink}
              travelDate={travelDate}
              setSelectedLink={setSelectedLink}
              setTravelDate={setTravelDate}
              onSearch={handleSearch}
            />
          </div>
        </div>
        <div className="flex-grow text-center py-12">
          <p className="text-gray-500">No search data available. Please perform a search first.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <div className="relative">
          <Hero slides={heroSlides} />
          <div id="buy-tickets" className="relative -mt-12 z-10">
            <BuyTickets
              routes={routes}
              links={links}
              onPurchase={() => {}}
              onRouteChange={handleRouteChange}
              selectedRoute={selectedRoute}
              selectedLink={selectedLink}
              travelDate={travelDate}
              setSelectedLink={setSelectedLink}
              setTravelDate={setTravelDate}
              onSearch={handleSearch}
            />
          </div>
        </div>

        <section ref={schedulesSectionRef} className="w-full py-12 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-4 text-gray-900">
              Available Schedules
            </h2>
            <div className="flex justify-center items-center space-x-2 mb-8">
              <p className="text-center text-gray-600">
                Select a schedule for your trip from {from} to {to} on {travelDate}.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {schedules.length > 0 ? (
                schedules.map(({ link, departureTime }, index) => (
                  <div
                    key={`${link.link_id}-${departureTime}-${index}`}
                    className={`p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 border-2 ${
                      selectedSchedule?.link.link_id === link.link_id &&
                      selectedSchedule?.departureTime === departureTime
                        ? 'border-blue-500'
                        : 'border-transparent'
                    }`}
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold text-gray-900">
                        {from}
                      </h3>
                      {selectedSchedule?.link.link_id === link.link_id &&
                        selectedSchedule?.departureTime === departureTime && (
                          <CheckCircleIcon className="h-6 w-6 text-blue-500" />
                        )}
                    </div>
                    <div className="flex items-center mb-3">
                      <ArrowRightIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-gray-700 font-medium">
                        to {link.link_name}
                      </span>
                    </div>
                    <div className="flex items-center mb-3">
                      <ClockIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-gray-700">
                        {formatTime(departureTime)}
                      </span>
                    </div>
                    <div className="flex items-center mb-3">
                      <UserIcon className="h-5 w-5 text-gray-500 mr-2" />
                      <span className="text-lg font-semibold text-blue-600">
                        ${link.price || 'N/A'}
                      </span>
                    </div>
                    <div>
                      <button
                        onClick={() => handleSelectSchedule(link, departureTime)}
                        className={`w-full px-4 py-2 font-semibold rounded-full shadow transition-colors duration-300 ${
                          selectedSchedule?.link.link_id === link.link_id &&
                          selectedSchedule?.departureTime === departureTime
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                      >
                        Select
                      </button>
                      <p className="text-center text-gray-600 text-sm mt-2">
                        {returnDate ? 'Round Trip' : 'One Way'}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 col-span-full">
                  No schedules available for this route on the selected date.
                </p>
              )}
            </div>
          </div>
        </section>

        <div
          className={`fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 ${
            selectedSchedule ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="h-full flex flex-col">
            <div className="p-6 border-b border-gray-200 flex justify-between items-center bg-gradient-to-r from-blue-50 to-white">
              <h3 className="text-xl font-bold text-gray-900">Trip Summary</h3>
              <button onClick={handleCloseDrawer} className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="flex-grow p-6 overflow-y-auto">
              {selectedSchedule ? (
                <div className="space-y-6">
                  <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <h4 className="text-lg font-semibold text-gray-800 mb-3">Travel Details</h4>
                    <div className="space-y-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Route:</span>
                        </div>
                        <p className="text-gray-900 font-semibold text-right">{selectedRouteName}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">To:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">{selectedSchedule.link.link_name}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <ClockIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Departure Time:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">{formatTime(selectedSchedule.departureTime)}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <CalendarIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Departure Date:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">{travelDate}</p>
                      </div>
                      {returnDate && (
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <CalendarIcon className="h-5 w-5 text-gray-500" />
                            <span className="text-gray-600 font-medium">Return Date:</span>
                          </div>
                          <p className="text-gray-900 font-semibold">{returnDate}</p>
                        </div>
                      )}
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <UsersIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Passengers:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">{passengers}</p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <h4 className="text-lg font-semibold text-gray-800 mb-3">Price Breakdown</h4>
                    <div className="space-y-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Price per Person:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">${selectedSchedule.link.price || 'N/A'}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Trip Type:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">{returnDate ? 'Round Trip' : 'One Way'}</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-600 font-medium">Services Tax:</span>
                        </div>
                        <p className="text-gray-900 font-semibold">${servicesTax.toFixed(2)}</p>
                      </div>
                      <hr className="my-2 border-gray-200" />
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <CurrencyDollarIcon className="h-5 w-5 text-blue-500" />
                          <span className="text-gray-600 font-medium">Subtotal:</span>
                        </div>
                        <p className="text-blue-600 font-bold text-lg">${subtotal.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-gray-500 text-center">Select a schedule to see the trip summary.</p>
              )}
            </div>

            {selectedSchedule && (
              <div className="p-6 border-t border-gray-200 flex space-x-4">
                <button
                  onClick={handleCloseDrawer}
                  className="flex-1 px-4 py-3 bg-gray-200 text-gray-700 font-semibold rounded-full shadow hover:bg-gray-300 transition-colors duration-300"
                >
                  Close
                </button>
                <button
                  onClick={handlePayOrder}
                  className="flex-1 px-4 py-3 bg-blue-600 text-white font-semibold rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
                >
                  Confirm Order
                </button>
              </div>
            )}
          </div>
        </div>

        {selectedSchedule && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={handleCloseDrawer}
          />
        )}
      </main>
    </div>
  );
};

export default SearchResults;