import React, { useState, useEffect } from 'react';
import { useOutletContext, useLocation, useParams } from 'react-router-dom';
import Hero from './Hero';
import BuyTickets from './BuyTickets';
import AvailableRoutes from './AvailableRoutes';
import Reviews from './Reviews';
import AboutUs from './AboutUs';
import RouteInfoPanel from './RouteInfoPanel';

const LandingPage = () => {
  const { companyData, landingPage, routes, links } = useOutletContext();
  const { state } = useLocation();
  const { companySlug } = useParams();

  const [selectedRoute, setSelectedRoute] = useState('');
  const [selectedLink, setSelectedLink] = useState('');
  const [travelDate, setTravelDate] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [panelRoute, setPanelRoute] = useState(null);
  const [panelSchedules, setPanelSchedules] = useState([]);
  const [pickupDropoff, setPickupDropoff] = useState(null);

  useEffect(() => {
    if (state?.paymentSuccess) {
      setPaymentSuccess(true);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [state]);

  const heroSlides = [];
  if (landingPage && Object.keys(landingPage).length > 0) {
    for (let i = 1; i <= 3; i++) {
      const imageUrl = landingPage[`hero_image_${i}_url`];
      if (imageUrl) {
        heroSlides.push({
          url: imageUrl,
          title: landingPage[`hero_image_${i}_title`],
          paragraph: landingPage[`hero_image_${i}_paragraph`],
          buttonText: landingPage[`hero_image_${i}_button_text`],
          buttonUrl: landingPage[`hero_image_${i}_button_url`],
        });
      }
    }
  }

  const handleRouteChange = (e) => {
    const routeId = e.target.value;
    setSelectedRoute(routeId);
    setSelectedLink('');
  };

  const handlePanelRouteSelect = async (route) => {
    setPanelRoute(route);
    try {
      const scheduleRes = await fetch(`${process.env.REACT_APP_API_URL}/public/schedules/${route.linkId}`);
      const scheduleData = await scheduleRes.json();
      setPanelSchedules(scheduleData || []);

      const dropoffRes = await fetch(`${process.env.REACT_APP_API_URL}/api/pickup-dropoff/active/${route.linkId}`);
      const dropoffData = await dropoffRes.json();
      setPickupDropoff({ pickup: dropoffData.data, dropoff: dropoffData.data });
    } catch (error) {
      console.error('Error loading route details:', error);
    }
  };

  const handleClosePanel = () => {
    setPanelRoute(null);
    setPanelSchedules([]);
    setPickupDropoff(null);
  };

  if (!companyData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        {paymentSuccess && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mx-4 mt-4 rounded-lg">
            <p className="font-semibold">Purchase completed successfully!</p>
            <p>Thank you for your booking. You'll receive a confirmation email shortly.</p>
          </div>
        )}
        <div className="relative">
          <Hero slides={heroSlides} />
          <div id="buy-tickets" className="relative -mt-12 z-10">
            <BuyTickets
              routes={routes}
              links={links}
              onPurchase={() => {}}
              onRouteChange={handleRouteChange}
              selectedRoute={selectedRoute}
              selectedLink={selectedLink}
              travelDate={travelDate}
              setSelectedLink={setSelectedLink}
              setTravelDate={setTravelDate}
            />
          </div>
        </div>

        <AvailableRoutes routes={routes} links={links} onSelect={handlePanelRouteSelect} />
        {panelRoute && (
          <RouteInfoPanel
            route={panelRoute}
            schedules={panelSchedules}
            pickupDropoff={pickupDropoff}
            onClose={handleClosePanel}
            companySlug={companySlug}
          />
        )}
        <Reviews />
        {(landingPage?.about_us_title || landingPage?.about_us_content || landingPage?.about_us_image_url) && (
          <AboutUs
            title={landingPage.about_us_title}
            imageUrl={landingPage.about_us_image_url}
            content={landingPage.about_us_content}
          />
        )}
        {(landingPage?.contact_us_title || landingPage?.contact_us_content) && (
          <section className="w-full py-12">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
              {landingPage?.contact_us_title && (
                <h2 className="text-2xl font-semibold text-center mb-6 text-gray-900">
                  {landingPage.contact_us_title}
                </h2>
              )}
              {landingPage?.contact_us_content && (
                <p className="text-gray-700 text-center">
                  {landingPage.contact_us_content}
                </p>
              )}
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default LandingPage;