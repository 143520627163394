import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const CompanyLayout = () => {
  const { companySlug } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [landingPage, setLandingPage] = useState({});
  const [routes, setRoutes] = useState([]);
  const [links, setLinks] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL || 'https://admin.kuvaway.com';

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        setLoading(true);
        if (!companySlug) {
          throw new Error('El companySlug no está definido en la URL');
        }

        // 1. Obtener los datos de la compañía usando el companySlug
        const companyResponse = await fetch(`${API_URL}/api/company-by-slug/${companySlug}`);
        if (!companyResponse.ok) {
          const errorText = await companyResponse.text();
          throw new Error(`Error al cargar la empresa (status ${companyResponse.status}): ${errorText}`);
        }
        const companyData = await companyResponse.json();
        setCompanyData(companyData);
        setCompanyId(companyData.company_id);

        // 2. Obtener la página de aterrizaje usando el companyId
        try {
          const landingPageResponse = await fetch(`${API_URL}/api/landing-pages/${companyData.company_id}`);
          let landingPageData = {};
          if (landingPageResponse.ok) {
            landingPageData = await landingPageResponse.json();
          } else {
            const errorText = await landingPageResponse.text();
            console.error(`Error al cargar landing page (status ${landingPageResponse.status}): ${errorText}`);
          }
          setLandingPage(landingPageData);
        } catch (landingError) {
          console.error('Error fetching landing page:', landingError);
        }

        // 3. Obtener las rutas y enlaces
        try {
          const routesResponse = await fetch(`${API_URL}/api/companies/${companyData.company_id}/routes`);
          let routesData = [];
          if (routesResponse.ok) {
            routesData = await routesResponse.json();
          }
          setRoutes(routesData);

          const linksData = {};
          for (const route of routesData) {
            try {
              const linksResponse = await fetch(`${API_URL}/api/routes/${route.route_id}/links`);
              if (linksResponse.ok) {
                const routeLinks = await linksResponse.json();
                linksData[route.route_id] = routeLinks;
              }
            } catch (linkError) {
              console.error(`Error fetching links for route ${route.route_id}:`, linkError);
            }
          }
          setLinks(linksData);
        } catch (routesError) {
          console.error('Error fetching routes:', routesError);
        }
      } catch (error) {
        console.error('Error al cargar los datos de la empresa:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [companySlug, navigate]);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar companyData={companyData} landingPage={landingPage} companySlug={companySlug} />
      <main className="flex-grow">
        {loading && (
          <div className="text-center py-12">
            <p className="text-gray-500">Cargando datos de la empresa...</p>
          </div>
        )}
        {error && (
          <div className="text-center py-4">
            <p className="text-red-500 text-lg">{error}</p>
            <p className="text-gray-500">Por favor, verifica que la URL sea correcta o intenta de nuevo más tarde.</p>
          </div>
        )}
        <Outlet context={{ companyData, companySlug, companyId, landingPage, routes, links }} />
      </main>
      <Footer companySlug={companySlug} />
    </div>
  );
};

export default CompanyLayout;