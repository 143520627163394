import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  XMarkIcon,
  ArrowRightIcon,
  CurrencyDollarIcon,
  ClockIcon,
  MapPinIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

const RouteInfoPanel = ({ route, schedules, pickupDropoff, onClose, companySlug }) => {
  const { pickup, dropoff } = pickupDropoff || {};
  const [routeNote, setRouteNote] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [travelDate, setTravelDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [passengers, setPassengers] = useState(1);
  const navigate = useNavigate();

  const pickupLocation = pickup ? pickup.effective_pickup : 'N/A';
  const dropoffLocation = dropoff ? dropoff.effective_dropoff : 'N/A';
  const isPickupConfigured = pickup?.is_active === 1 && pickup.admin_pickup;
  const isDropoffConfigured = dropoff?.is_active === 1 && dropoff.admin_dropoff;

  useEffect(() => {
    if (!route) return;
    const fetchRouteNote = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL || 'https://admin.kuvaway.com'}/route-notes/${route.routeId}`
        );
        const data = await res.json();
        if (data?.note) setRouteNote(data.note);
      } catch (error) {
        console.error('Error fetching route note:', error);
      }
    };
    fetchRouteNote();
  }, [route]);

  if (!route) return null;

  const handleConfirm = () => {
    const selectedSchedule = schedules.find((s) => s.schedule_time === selectedTime);

    const purchaseData = {
      route_id: route.routeId,
      link_id: route.linkId,
      schedule_id: selectedSchedule?.schedule_id || null,
      schedule_time: selectedTime,
      travel_date: travelDate,
      return_date: returnDate || null,
      passengers,
      route_name: route.routeName,
      link_name: route.linkName,
      price: route.price,
    };

    navigate(`/checkout?company=${companySlug}`, { state: { purchaseData, companySlug } });
  };

  const handleClose = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      onClose();
    }
  };

  return (
    <div className="fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 translate-x-0">
      <div className="h-full flex flex-col">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center bg-gradient-to-r from-blue-50 to-white">
          <h3 className="text-xl font-bold text-gray-900">Route Information</h3>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        {/* CONTINUACIÓN DEL CÓDIGO VISUAL (details, schedules, pickup/dropoff, trip form, footer) */}
      </div>
    </div>
  );
};

export default RouteInfoPanel;