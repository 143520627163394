import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/img/transporzuma-checkout.svg';

const ThankYou = () => {
  const { state } = useLocation();
  const {
    orderId,
    companySlug,
    travelDate,
    returnDate,
    routeName,
    toName,
    departureTime,
    passengers,
    subtotal,
    paymentId,
    transactionId,
    tickets,
  } = state || {};

  if (!orderId) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500 text-lg">Error: No order data provided</p>
        <p className="text-gray-500">Please complete your booking to see this page.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {/* Left side: Trip Details and Payment Details */}
      <div className="lg:w-1/2 bg-blue-900 text-white flex flex-col justify-center items-center p-8">
        <div className="mb-8">
          <img src={logo} alt="KUV - AWAY" className="h-12 w-auto" />
        </div>
        <div className="max-w-md w-full text-left">
          <h2 className="text-2xl font-bold mb-6">Thank You for Your Booking!</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold mb-3">Trip Details</h3>
              <div className="space-y-2">
                <div className="flex justify-between"><span className="text-blue-200">Order ID</span><span className="font-medium">{orderId}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Route</span><span className="font-medium">{routeName || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">To</span><span className="font-medium">{toName || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Departure Time</span><span className="font-medium">{departureTime || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Departure Date</span><span className="font-medium">{travelDate || 'N/A'}</span></div>
                {returnDate && <div className="flex justify-between"><span className="text-blue-200">Return Date</span><span className="font-medium">{returnDate}</span></div>}
                <div className="flex justify-between"><span className="text-blue-200">Passengers</span><span className="font-medium">{passengers || 1}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Trip Type</span><span className="font-medium">{returnDate ? 'Round Trip' : 'One Way'}</span></div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-3">Payment Details</h3>
              <div className="space-y-2">
                <div className="flex justify-between"><span className="text-blue-200">Payment ID</span><span className="font-medium">{paymentId}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Transaction ID</span><span className="font-medium">{transactionId}</span></div>
                <div className="flex justify-between text-lg font-semibold mt-2"><span>Total</span><span>${subtotal.toFixed(2)}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-lg font-bold">KUV - AWAY</p>
          <p className="text-sm text-blue-200">LAUNCH YOUR NEXT JOURNEY</p>
        </div>
      </div>

      {/* Right side: Tickets and Booking Confirmed */}
      <div className="lg:w-1/2 bg-white flex items-center justify-center p-8">
        <div className="max-w-md w-full text-center">
          {tickets && tickets.length > 0 && (
            <div className="mb-8">
              <h3 className="text-lg font-semibold mb-3 text-gray-800">Your Tickets</h3>
              <div className="space-y-4">
                {tickets.map((ticket, index) => (
                  <div key={ticket.ticket_id} className="border border-gray-300 p-4 rounded-lg">
                    <h4 className="text-md font-semibold text-gray-800">Ticket {index + 1}</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between"><span className="text-gray-600">Ticket ID</span><span className="font-medium">{ticket.ticket_id}</span></div>
                      <div className="flex justify-between"><span className="text-gray-600">Status</span><span className="font-medium">{ticket.status}</span></div>
                      <div className="flex justify-between"><span className="text-gray-600">Expires At</span><span className="font-medium">{new Date(ticket.expires_at).toLocaleString()}</span></div>
                      <div className="flex justify-center">
                        <img src={ticket.qr_code} alt={`QR Code for ${ticket.ticket_id}`} className="w-32 h-32" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <h1 className="text-3xl font-bold text-gray-800 mb-4">Booking Confirmed!</h1>
          <p className="text-gray-600 mb-6">Thank you for your purchase. Please present the QR codes above to the driver.</p>
          <a
            href="/my-account"
            className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
          >
            View My Tickets
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;