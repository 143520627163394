import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const Schedules = () => {
  const { companySlug, routes, links } = useOutletContext(); // Obtener routes y links del contexto
  const [schedules, setSchedules] = useState({});
  const [expandedRoutes, setExpandedRoutes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Obtener los horarios para cada enlace
  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        setLoading(true);
        setError(null);

        const schedulesData = {};
        // Iterar sobre todas las rutas y sus enlaces para obtener los horarios
        for (const route of routes) {
          const routeLinks = links[route.route_id] || [];
          for (const link of routeLinks) {
            const schedulesRes = await fetch(`/public/schedules/${link.link_id}`, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!schedulesRes.ok) throw new Error(`Error al obtener los horarios para el enlace ${link.link_id}`);
            const schedulesForLink = await schedulesRes.json();
            schedulesData[link.link_id] = schedulesForLink;
          }
        }

        setSchedules(schedulesData);
      } catch (err) {
        console.error('Error al cargar los horarios:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (routes && links) {
      fetchSchedules();
    }
  }, [routes, links]);

  // Manejar el colapsar/expandir de las rutas
  const toggleRoute = (routeId) => {
    setExpandedRoutes((prev) => ({
      ...prev,
      [routeId]: !prev[routeId],
    }));
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto py-12 px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Schedules</h1>
        <p className="text-gray-600">Loading schedules...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto py-12 px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Schedules</h1>
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Available Schedules</h1>
      <p className="text-gray-600 mb-8">
        Below are the available routes, their links, and departure times for {companySlug}.
      </p>

      {routes.length === 0 ? (
        <p className="text-gray-500">No routes available for this company.</p>
      ) : (
        <div className="space-y-6">
          {routes.map((route) => (
            <div
              key={route.route_id}
              className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden"
            >
              {/* Encabezado de la tarjeta */}
              <div
                className="flex items-center justify-between p-5 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors"
                onClick={() => toggleRoute(route.route_id)}
              >
                <div className="flex items-center space-x-3">
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800">{route.route_name}</h2>
                    <p className="text-sm text-gray-600">
                      Type: {route.route_type} | Status:{' '}
                      <span
                        className={`inline-block px-2 py-0.5 text-xs rounded-full font-semibold ${
                          route.status === 'active'
                            ? 'bg-green-100 text-green-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        {route.status.charAt(0).toUpperCase() + route.status.slice(1)}
                      </span>
                    </p>
                    {route.notes && (
                      <p className="text-sm text-gray-500 mt-1">Notes: {route.notes}</p>
                    )}
                  </div>
                </div>
                <div>
                  {expandedRoutes[route.route_id] ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </div>

              {/* Contenido colapsable */}
              {expandedRoutes[route.route_id] && (
                <div className="p-5 border-t border-gray-200">
                  {links[route.route_id]?.length > 0 ? (
                    <div className="space-y-4">
                      {links[route.route_id].map((link) => (
                        <div
                          key={link.link_id}
                          className="border-l-4 border-blue-500 pl-4 py-2 bg-gray-50 rounded-r-lg"
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <h3 className="text-md font-medium text-gray-800">
                                {link.link_name}
                              </h3>
                              <p className="text-sm text-gray-600">
                                Price: ${Number(link.price || 0).toFixed(2)} | Status:{' '}
                                <span
                                  className={`inline-block px-2 py-0.5 text-xs rounded-full font-semibold ${
                                    link.status === 'active'
                                      ? 'bg-green-100 text-green-700'
                                      : 'bg-red-100 text-red-700'
                                  }`}
                                >
                                  {link.status
                                    ? link.status.charAt(0).toUpperCase() + link.status.slice(1)
                                    : 'N/A'}
                                </span>
                              </p>
                              {link.notes && (
                                <p className="text-sm text-gray-500 mt-1">Notes: {link.notes}</p>
                              )}
                            </div>
                          </div>

                          {/* Horarios de salida */}
                          <div className="mt-3">
                            <h4 className="text-sm font-semibold text-gray-700 mb-2">
                              Departure Times:
                            </h4>
                            {schedules[link.link_id]?.length > 0 ? (
                              <div className="flex flex-wrap gap-2">
                                {schedules[link.link_id].map((schedule) => (
                                  <div
                                    key={schedule.id}
                                    className="bg-blue-100 text-blue-800 text-xs font-medium px-3 py-1 rounded-full"
                                  >
                                    {schedule.schedule_time}{' '}
                                    <span
                                      className={`inline-block px-2 py-0.5 text-xs rounded-full font-semibold ${
                                        schedule.status === 'active'
                                          ? 'bg-green-100 text-green-700'
                                          : 'bg-red-100 text-red-700'
                                      }`}
                                    >
                                      {schedule.status.charAt(0).toUpperCase() +
                                        schedule.status.slice(1)}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="text-sm text-gray-500">No schedules available.</p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500 p-4">No links available for this route.</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Schedules;