// components/ForgotPassword.js
import React, { useState, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const ForgotPassword = () => {
  const { companySlug } = useParams();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!email) {
      setError('El correo electrónico es obligatorio');
      return;
    }

    if (!validateEmail(email)) {
      setError('Por favor, ingresa un correo electrónico válido');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://admin.kuvaway.com/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, companySlug }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Hemos enviado un código a tu correo. Ingresa el código de seguridad.');
        setError('');
        setShowCodeInput(true);
      } else {
        if (data.message === 'Correo no encontrado') {
          setError('El correo electrónico no está registrado');
        } else {
          // Mostrar el mensaje completo del backend, incluyendo details
          setError(`${data.message}${data.details ? `: ${data.details}` : ''}`);
        }
        setMessage('');
      }
    } catch (err) {
      setError('Error al enviar la solicitud. Verifica tu conexión e intenta de nuevo');
      setMessage('');
      console.error('ForgotPassword - Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeChange = (index, value) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    const fullCode = code.join('');
    if (fullCode.length !== 6) {
      setError('Por favor, ingresa un código de 6 dígitos');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://admin.kuvaway.com/api/auth/verify-reset-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: fullCode }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setUser(data.user);
        setMessage('Código verificado exitosamente. Iniciando sesión...');
        setTimeout(() => {
          navigate(`/${companySlug}/my-account`);
        }, 1500);
      } else {
        if (data.message === 'Código inválido o no encontrado') {
          setError('El código ingresado es incorrecto');
        } else if (data.message === 'El código ha expirado') {
          setError('El código ha expirado. Solicita un nuevo código');
          setShowCodeInput(false);
        } else {
          setError(data.message || 'Error al verificar el código');
        }
        setMessage('');
      }
    } catch (err) {
      setError('Error al verificar el código. Verifica tu conexión e intenta de nuevo');
      setMessage('');
      console.error('ForgotPassword - Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full mx-auto p-8 bg-white rounded-lg shadow-lg">
        {!showCodeInput ? (
          <>
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Restablecer Contraseña</h1>
            <p className="text-gray-600 text-center mb-6">
              Ingresa tu correo electrónico para recibir un código de verificación.
            </p>

            <form onSubmit={handleEmailSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`mt-1 block w-full p-3 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ${
                    error && !validateEmail(email) ? 'border-red-500' : 'border-gray-300'
                  }`}
                  placeholder="ejemplo@correo.com"
                  required
                  disabled={loading}
                />
              </div>

              {message && (
                <div className="p-4 bg-green-100 text-green-700 rounded-md text-sm">
                  {message}
                </div>
              )}
              {error && (
                <div className="p-4 bg-red-100 text-red-700 rounded-md text-sm">
                  {error}
                </div>
              )}

              <button
                type="submit"
                className={`w-full py-3 px-4 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Enviando...
                  </div>
                ) : (
                  'Enviar Código de Verificación'
                )}
              </button>
            </form>
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Verificar Código</h1>
            <p className="text-gray-600 text-center mb-6">
              Hemos enviado un código a tu correo. Ingresa el código de seguridad.
            </p>

            <form onSubmit={handleCodeSubmit} className="space-y-6">
              <div className="flex justify-center space-x-2">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    id={`code-input-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    maxLength="1"
                    className="w-12 h-12 text-center text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                    disabled={loading}
                  />
                ))}
              </div>

              {message && (
                <div className="p-4 bg-green-100 text-green-700 rounded-md text-sm">
                  {message}
                </div>
              )}
              {error && (
                <div className="p-4 bg-red-100 text-red-700 rounded-md text-sm">
                  {error}
                </div>
              )}

              <button
                type="submit"
                className={`w-full py-3 px-4 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Verificando...
                  </div>
                ) : (
                  'Verificar Código'
                )}
              </button>
            </form>
          </>
        )}

        <p className="mt-6 text-center text-sm text-gray-600">
          ¿Ya tienes una cuenta?{' '}
          <Link to={`/${companySlug}/log-in`} className="text-blue-600 hover:underline font-medium">
            Inicia sesión aquí
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;