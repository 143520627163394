// components/AboutUs.js
import React from 'react';

const AboutUs = ({ title, imageUrl, content }) => {
  return (
    <section className="w-full py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Columna de la imagen */}
          {imageUrl && (
            <div className="order-2 lg:order-1">
              <img
                src={imageUrl}
                alt="About Us"
                className="w-full h-80 object-cover rounded-lg shadow-lg"
              />
            </div>
          )}

          {/* Columna del texto */}
          <div className={`order-1 lg:order-2 ${!imageUrl ? 'col-span-1 lg:col-span-2 text-center' : ''}`}>
            {title && (
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                {title}
              </h2>
            )}
            {content && (
              <p className="text-gray-700 text-lg">
                {content}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;