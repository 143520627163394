// components/ForgotPassword.js (para clientes en client.kuvaway.com)
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const ForgotPassword = () => {
  const { companySlug } = useParams();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Validar el formato del correo electrónico
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    // Validar que el campo de correo no esté vacío
    if (!email) {
      setError('El correo electrónico es obligatorio');
      return;
    }

    // Validar el formato del correo
    if (!validateEmail(email)) {
      setError('Por favor, ingresa un correo electrónico válido');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://admin.kuvaway.com/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, companySlug }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Se ha enviado un enlace de restablecimiento a tu correo electrónico. Revisa tu bandeja de entrada o spam.');
        setError('');
        setEmail(''); // Limpiar el campo de correo después de un envío exitoso
      } else {
        // Manejar errores específicos del backend
        if (data.message === 'Correo no encontrado') {
          setError('El correo electrónico no está registrado');
        } else if (data.message === 'Error al enviar el correo de restablecimiento') {
          setError('Error al enviar el correo de restablecimiento. Intenta de nuevo más tarde');
        } else {
          setError(data.message || 'Error al procesar la solicitud');
        }
        setMessage('');
      }
    } catch (err) {
      setError('Error al enviar la solicitud. Verifica tu conexión e intenta de nuevo');
      setMessage('');
      console.error('ForgotPassword - Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Restablecer Contraseña</h1>
        <p className="text-gray-600 text-center mb-6">
          Ingresa tu correo electrónico para recibir un enlace de restablecimiento de contraseña.
        </p>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Correo Electrónico
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`mt-1 block w-full p-3 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ${
                error && !validateEmail(email) ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="ejemplo@correo.com"
              required
              disabled={loading}
            />
          </div>

          {/* Mostrar mensajes de éxito o error */}
          {message && (
            <div className="p-4 bg-green-100 text-green-700 rounded-md text-sm">
              {message}
            </div>
          )}
          {error && (
            <div className="p-4 bg-red-100 text-red-700 rounded-md text-sm">
              {error}
            </div>
          )}

          <button
            type="submit"
            className={`w-full py-3 px-4 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                Enviando...
              </div>
            ) : (
              'Enviar Enlace de Restablecimiento'
            )}
          </button>
        </form>

        <p className="mt-6 text-center text-sm text-gray-600">
          ¿Ya tienes una cuenta?{' '}
          <Link to={`/${companySlug}/log-in`} className="text-blue-600 hover:underline font-medium">
            Inicia sesión aquí
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;