import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../components/context/UserContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = ({ companyData, landingPage, companySlug }) => {
  const { user, logout, loading, error } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const companyName = landingPage?.hero_image_1_title || companyData?.company_name || 'Loading...';

  // Eliminamos "Schedules" y "Contact Us" del array de navegación
  const navigation = [
    { name: 'Home', href: `/${companySlug}`, current: location.pathname === `/${companySlug}` },
    { name: 'Routes', href: `/${companySlug}/archive-routes`, current: location.pathname === `/${companySlug}/archive-routes` },
  ];

  const handleLogout = () => {
    logout(companySlug, navigate);
  };

  const getUserInitials = () => {
    if (!user) return null;
    const first = user.first_name?.[0]?.toUpperCase() || '';
    const last = user.last_name?.[0]?.toUpperCase() || '';
    return `${first}${last}`;
  };

  return (
    <Disclosure as="nav" className="sticky top-0 z-50 bg-blue-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-blue-700 hover:text-white focus:ring-2 focus:ring-white focus:outline-hidden focus:ring-inset">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block size-6 group-data-open:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden size-6 group-data-open:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex shrink-0 items-center">
              {landingPage?.header_logo_url ? (
                <img
                  alt={`${companyName} Logo`}
                  src={landingPage.header_logo_url}
                  className="h-8 w-auto"
                />
              ) : (
                <Link to={`/${companySlug}`} className="text-white text-lg font-bold">
                  {companyName}
                </Link>
              )}
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current
                        ? 'bg-blue-900 text-white'
                        : 'text-gray-300 hover:bg-blue-700 hover:text-white',
                      'rounded-md px-3 py-2 text-sm font-medium'
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {loading ? (
              <span className="text-gray-300">Loading...</span>
            ) : error ? (
              <span className="text-red-300">Error</span>
            ) : (
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex rounded-full bg-blue-800 text-sm focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-800 focus:outline-hidden">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    {user ? (
                      <div className="size-8 rounded-full bg-white text-blue-800 font-semibold flex items-center justify-center">
                        {getUserInitials()}
                      </div>
                    ) : (
                      <div className="size-8 rounded-full bg-white flex items-center justify-center">
                        <UserIcon className="size-6 text-blue-800" aria-hidden="true" />
                      </div>
                    )}
                  </MenuButton>
                </div>
                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 ring-1 shadow-lg ring-black/5 focus:outline-hidden">
                  {user ? (
                    <>
                      <MenuItem>
                        <Link
                          to={`/${companySlug}/my-account`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          My Orders
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to={`/${companySlug}/support`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Support
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to={`/${companySlug}/security`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Security
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign Out
                        </button>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Link
                          to={`/${companySlug}/log-in`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Log In
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link
                          to={`/${companySlug}/sign-in`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign Up
                        </Link>
                      </MenuItem>
                    </>
                  )}
                </MenuItems>
              </Menu>
            )}
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pt-2 pb-3">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as={Link}
              to={item.href}
              aria-current={item.current ? 'page' : undefined}
              className={classNames(
                item.current ? 'bg-blue-900 text-white' : 'text-gray-300 hover:bg-blue-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium'
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default Navbar;