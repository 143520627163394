import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ companySlug }) => {
  const [companyName, setCompanyName] = useState('company name'); // Default value

  useEffect(() => {
    const fetchLandingPageData = async () => {
      try {
        if (!companySlug) {
          console.warn('companySlug is not defined, using default name');
          return;
        }

        // Fetch landing page data from the API based on companySlug
        const response = await fetch(`https://admin.kuvaway.com/api/landing-page/${companySlug}`);
        if (!response.ok) {
          throw new Error('Error fetching landing page data');
        }
        const data = await response.json();
        console.log('Landing page data:', data);

        // Use hero_image_1_title as the company name
        if (data.hero_image_1_title) {
          setCompanyName(data.hero_image_1_title);
        }
      } catch (error) {
        console.error('Error fetching landing page data:', error);
        // Keep the default value if there’s an error
        setCompanyName('company name');
      }
    };

    if (companySlug) {
      fetchLandingPageData();
    }
  }, [companySlug]);

  return (
    <footer className="bg-blue-900 text-white py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center space-y-4 md:flex-row md:justify-between md:space-y-0">
          {/* Company Name and Copyright */}
          <div className="text-center md:text-left">
            <p className="text-sm">
              {companyName} | KUV Away © 2025
            </p>
          </div>

          {/* Navigation Links */}
          <div className="flex space-x-6">
            <Link
              to={`/${companySlug}/terms-and-conditions`}
              className="text-sm text-gray-300 hover:text-white"
            >
              Terms and Conditions
            </Link>
            <Link
              to={`/${companySlug}/privacy-policy`}
              className="text-sm text-gray-300 hover:text-white"
            >
              Privacy Policy
            </Link>
            <Link
              to={`/${companySlug}/help-center`}
              className="text-sm text-gray-300 hover:text-white"
            >
              Help Center
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;