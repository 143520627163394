import React from 'react';
import { useOutletContext } from 'react-router-dom';

const SignIn = () => {
  const { companySlug } = useOutletContext();

  return (
    <div className="max-w-md mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Sign In</h1>
      <p className="text-gray-600 mb-6">This is a placeholder for the Sign In page.</p>
      <p><strong>Company Slug:</strong> {companySlug}</p>
      {/* Add your sign-in form here */}
    </div>
  );
};

export default SignIn;