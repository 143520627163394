import React from 'react';

const Support = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Welcome to Transporzuma!</h1>
        <p className="text-gray-500 mb-4">
          Reliable and efficient transportation. Comfortable and direct trips to various destinations in Costa Rica.
        </p>
        <p className="text-gray-500 mb-6">
          Visit our website: <a href="https://www.transporzuma.com" className="text-blue-500">www.transporzuma.com</a>
        </p>
        <p className="text-gray-500 mb-4">
          For assistance with your reservation such as changing dates, cancellations, or refunds, please contact us:
        </p>
        <ul className="list-none space-y-2 text-gray-500">
          <li>Email: <a href="mailto:reservaciones@transporzuma.com" className="text-blue-500">reservaciones@transporzuma.com</a></li>
          <li>Phone: +506 8821 6277</li>
          <li>Operating hours: 06:00 AM to 05:00 PM, Monday to Sunday</li>
        </ul>
        <div className="mt-6">
          <a href="https://wa.me/50688216277" className="inline-flex items-center rounded-md bg-green-500 px-4 py-2 text-sm font-semibold text-white hover:bg-green-600">
            Contact us on WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
