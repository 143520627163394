import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';

const BuyTickets = ({
  routes,
  links,
  onPurchase,
  onRouteChange,
  selectedRoute,
  selectedLink,
  travelDate,
  setSelectedLink,
  setTravelDate,
  onSearch, // Nueva prop para manejar la búsqueda
}) => {
  const { companyData, companySlug } = useOutletContext();
  const navigate = useNavigate();

  // Estados para controlar la fecha de retorno y la cantidad de pasajeros
  const [returnDate, setReturnDate] = useState('');
  const [passengers, setPassengers] = useState(1);

  // Obtener la fecha actual en formato YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0];

  const handleSearch = (e) => {
    e.preventDefault();

    // Depuración: Mostrar el estado de companyData y companySlug
    console.log('companyData en BuyTickets:', companyData);
    console.log('companySlug en BuyTickets:', companySlug);

    // Validar que companyData y companySlug estén disponibles
    if (!companyData || !companySlug) {
      alert(
        'Error: No se pudo cargar la información de la empresa. ' +
        'Es posible que la empresa no exista o que el servidor no esté respondiendo. ' +
        'Por favor, verifica la URL o intenta de nuevo más tarde.'
      );
      return;
    }

    // Depuración: Mostrar los valores actuales
    console.log('selectedRoute:', selectedRoute);
    console.log('selectedLink:', selectedLink);
    console.log('travelDate:', travelDate);
    console.log('returnDate:', returnDate);
    console.log('passengers:', passengers);
    console.log('links:', links);
    console.log('links[selectedRoute]:', links[selectedRoute]);
    console.log('routes:', routes);

    // Validar campos requeridos
    if (!selectedRoute || !selectedLink || !travelDate) {
      alert('Please fill in all required fields (Route, Destination, and Travel Date).');
      return;
    }

    // Validar la cantidad de pasajeros
    if (!passengers || passengers < 1) {
      alert('Please enter a valid number of passengers (at least 1).');
      return;
    }

    // Validar que returnDate sea posterior a travelDate (si se proporciona)
    if (returnDate && returnDate < travelDate) {
      alert('Return Date must be on or after the Travel Date.');
      return;
    }

    // Asegurarnos de que links[selectedRoute] existe y tiene datos
    if (!links[selectedRoute] || links[selectedRoute].length === 0) {
      alert('No destinations available for the selected route.');
      return;
    }

    // Buscar el link seleccionado
    console.log('Buscando link con link_id:', selectedLink);
    const selectedLinkData = links[selectedRoute].find((link) => {
      console.log('Comparando link.link_id:', link.link_id, 'con selectedLink:', selectedLink);
      return String(link.link_id) === String(selectedLink);
    });

    if (!selectedLinkData) {
      console.log('No se encontró selectedLinkData. links[selectedRoute]:', links[selectedRoute]);
      alert('Please select a valid destination.');
      return;
    }

    // Obtener el nombre del origen (route_name)
    const selectedRouteData = routes.find(
      (route) => String(route.route_id) === String(selectedRoute)
    );

    if (!selectedRouteData) {
      alert('Please select a valid route.');
      return;
    }

    // Preparar los datos de la búsqueda
    const searchData = {
      from: selectedRouteData.route_name,
      to: selectedLinkData.link_name,
      travelDate,
      returnDate: returnDate || undefined,
      passengers: parseInt(passengers),
      links: links[selectedRoute],
      selectedRoute,
    };

    // Si onSearch está definido, llamarlo (para SearchResults)
    if (onSearch) {
      onSearch(searchData);
    } else {
      // Si no, navegar a SearchResults (comportamiento original)
      navigate(`/${companySlug}/search-results`, {
        state: searchData,
      });
    }
  };

  return (
    <div>
      <section className="w-full py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSearch}
            className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4 bg-white p-6 rounded-lg shadow-lg"
          >
            {/* From */}
            <div className="w-full md:w-auto">
              <label htmlFor="from" className="block text-sm font-medium text-gray-700">
                From
              </label>
              <select
                id="from"
                value={selectedRoute}
                onChange={onRouteChange}
                className="mt-1 block w-full md:w-52 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select a route...</option>
                {routes.map((route) => (
                  <option key={route.route_id} value={route.route_id}>
                    {route.route_name}
                  </option>
                ))}
              </select>
            </div>

            {/* To */}
            <div className="w-full md:w-auto">
              <label htmlFor="to" className="block text-sm font-medium text-gray-700">
                To
              </label>
              <select
                id="to"
                value={selectedLink}
                onChange={(e) => {
                  console.log('Nuevo valor de selectedLink:', e.target.value);
                  setSelectedLink(e.target.value);
                }}
                className="mt-1 block w-full md:w-52 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                disabled={!selectedRoute}
              >
                <option value="">Select a destination...</option>
                {selectedRoute &&
                  links[selectedRoute]?.map((link) => (
                    <option key={link.link_id} value={link.link_id}>
                      {link.link_name}
                    </option>
                  ))}
              </select>
            </div>

            {/* Date */}
            <div className="w-full md:w-auto">
              <label htmlFor="travel-date" className="block text-sm font-medium text-gray-700">
                Date
              </label>
              <input
                id="travel-date"
                type="date"
                value={travelDate}
                onChange={(e) => setTravelDate(e.target.value)}
                min={today}
                className="mt-1 block w-full md:w-52 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            {/* Return Date */}
            <div className="w-full md:w-auto">
              <label htmlFor="return-date" className="block text-sm font-medium text-gray-700">
                Return Date (Optional)
              </label>
              <input
                id="return-date"
                type="date"
                value={returnDate}
                onChange={(e) => setReturnDate(e.target.value)}
                min={travelDate || today}
                className="mt-1 block w-full md:w-52 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            {/* Quantity */}
            <div className="w-full md:w-auto">
              <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">
                Passengers
              </label>
              <input
                id="quantity"
                type="number"
                min="1"
                value={passengers}
                onChange={(e) => setPassengers(Math.max(1, parseInt(e.target.value)))}
                className="mt-1 block w-full md:w-28 p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            {/* Search Button */}
            <div className="w-full md:w-auto">
              <button
                type="submit"
                className="w-full md:w-auto px-6 py-2 text-white font-semibold rounded-full shadow hover:brightness-110 transition-colors"
                style={{ backgroundColor: 'oklch(0.424 0.199 265.638)' }}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default BuyTickets;
